import React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

const PowerNumbersPage = () => (
  <Layout>
    <Helmet>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <title>Power Numbers – Purple Cow Internet 💜🐄</title>
    </Helmet>
    <div className="mx-auto prose prose-xl">
      <h1>Power Numbers</h1>
      <ol>
        <li>Connect to your Purple Cow Wifi.</li>
        <li>
          Click this link: <a href="http://192.168.100.1">Power Numbers</a>
        </li>
        <li>
          Once you pull up the website please screen shot or save image to your
          device.{" "}
        </li>
        <li>
          Email image to{" "}
          <a
            href="mailto:purple@purplecowinternet.com?subject=Power Numbers&body=Here are my power numbers"
            target="_blank"
            rel="noreferrer"
          >
            purple@purplecowinternet.com
          </a>
        </li>
      </ol>

      <h2>Example Image</h2>
      <StaticImage
        layout="fixed"
        alt="Screenshot of power numbers"
        src="../content/powernumbers.png"
      />
    </div>
  </Layout>
)

export default PowerNumbersPage
